<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <!-- <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" /> -->

      <v-card elevation="0" outlined>
        <v-card-title class="orange-bg">
          <v-row>
            <v-col cols="12" md="3">
              <span class="font-weight-bold">Performace Report</span>
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-end">
              <v-btn small @click="downloadPdf">
                Download pdf
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- Start Performace Report section -->
          <v-container> </v-container>
          <!-- End Performace Report section -->
        </v-card-text>
        <v-card-actions>
          <v-btn @click="downloadPdf">Download pdf</v-btn>
        </v-card-actions>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
// import CommunityInfo from "@/view/components/CommunityInfo.vue";
// import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import {
  GET_QUERY,
  API_REPORTS,
  API_CUSTOMERS
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";

import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
// import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";

// import pdfmake from "pdfmake/build/pdfmake.js";

// import { required } from "vuelidate/lib/validators";
// import moment from "moment";

export default {
  mixins: [validationMixin],
  components: {
    // CommunityInfo,
    // InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    relatedCommunity: null,
    relatedCommunityItems: [],

    performaceReport: {
      items: []
    },
    options: {
      page: 1,
      itemsPerPage: 5
    },
    pageText: "",
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],

    orangeReportLoading: false,
    orangeReportServicesLoading: false,
    baseUrl: `${process.env.VUE_APP_DASHBOARD_URL}`,
    statusProcessing: false
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    },
    reportId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {},
  async mounted() {},
  async created() {
    // await this.getComunityInfo();
  },
  methods: {
    reportDataPromise() {
      return this.$store.dispatch(GET_QUERY, {
        listName: `${API_REPORTS}/performanceReport`
      });
    },
    urlToBase64(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = url;
        img.onload = () => {
          const canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d");
          canvas.height = img.height;
          canvas.width = img.width;
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL("image/jpeg"));
        };
        img.onerror = error => reject(error);
      });
    },
    async downloadPdf() {
      let pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      let self = this;
      let url = `${API_REPORTS}/performanceReport`;

      let reportResponse = await self.$store.dispatch(GET_QUERY, {
        listName: url
      });

      let reportData = reportResponse.data;

      //console.log(reportResponse.data);

      pdfMake.fonts = {
        Fontello: {
          normal: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
          bold: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
          italics: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
          bolditalics:
            self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf"
        },

        //download default Roboto font from cdnjs.com
        Roboto: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf"
        }
      };

      let reportBody = [];
      let header1 = [
        {
          text: "",
          rowSpan: 2,
          alignment: "center",
          border: [false, false, false, false]
        },
        {
          text: "Orange Reports",
          colSpan: 3,
          alignment: "center",
          border: [false, false, false, false],
          fillColor: "#df6526"
        },
        {},
        {},
        {
          text: "Service Requests",
          colSpan: 3,
          alignment: "center",
          border: [false, false, false, false],
          fillColor: "#37BCDF"
        },
        {},
        {},
        {
          text: "Current \nSentiment",
          rowSpan: 2,
          alignment: "center",
          border: [false, false, false, false],
          fillColor: "#37BCDF"
        }
      ];
      let header2 = [
        {},
        {
          text: "New",
          alignment: "center",
          border: [false, false, false, false],
          fillColor: "#df6526"
        },
        {
          text: "Pending",
          alignment: "center",
          border: [false, false, false, false],
          fillColor: "#df6526"
        },
        {
          text: "Approved",
          alignment: "center",
          border: [false, false, false, false],
          fillColor: "#df6526"
        },
        {
          text: "Open",
          alignment: "center",
          border: [false, false, false, false],
          fillColor: "#37BCDF"
        },
        {
          text: "Overdue",
          alignment: "center",
          border: [false, false, false, false],
          fillColor: "#37BCDF"
        },
        {
          text: "Closed",
          alignment: "center",
          border: [false, false, false, false],
          fillColor: "#37BCDF"
        },
        {}
      ];

      reportBody.push(header1);
      reportBody.push(header2);

      reportData.forEach(function(item) {
        let row = [];
        let propertyText = item.propertyName;
        propertyText += " (" + item.propertyLogins + " Logins)";
        propertyText += "\n";
        propertyText += item.accountManagerName;

        row.push({ text: propertyText, border: [true, true, true, true] });
        row.push({
          text: item.orangeReportsNew,
          border: [true, true, true, true],
          alignment: "center"
        });
        row.push({
          text: item.orangeReportsPending,
          border: [true, true, true, true],
          alignment: "center"
        });
        row.push({
          text: item.orangeReportsApproved,
          border: [true, true, true, true],
          alignment: "center"
        });
        row.push({
          text: item.serviceRequestsOpen,
          border: [true, true, true, true],
          alignment: "center"
        });
        row.push({
          text: item.serviceRequestsOverdue,
          border: [true, true, true, true],
          alignment: "center"
        });
        row.push({
          text: item.serviceRequestsClosed,
          border: [true, true, true, true],
          alignment: "center"
        });
        row.push({
          text: item.sentiment.toFixed(1),
          border: [true, true, true, true],
          alignment: "center"
        });

        reportBody.push(row);
      });

      // console.log(reportBody);

      let docDefinition = {
        images: {
          ynLogo: self.baseUrl + "/media/logos/yardnique-logo.png",
          sidebar: self.baseUrl + "/media/assets/aside-bg.jpg",
          "sentiment-0.0":
            self.baseUrl + "/media/assets/orange-report/Leaf_0.0.png",
          "sentiment-0.5":
            self.baseUrl + "/media/assets/orange-report/Leaf_0.5.png",
          "sentiment-1.0":
            self.baseUrl + "/media/assets/orange-report/Leaf_1.0.png",
          "sentiment-1.5":
            self.baseUrl + "/media/assets/orange-report/Leaf_1.5.png",
          "sentiment-2.0":
            self.baseUrl + "/media/assets/orange-report/Leaf_2.0.png",
          "sentiment-2.5":
            self.baseUrl + "/media/assets/orange-report/Leaf_2.5.png",
          "sentiment-3.0":
            self.baseUrl + "/media/assets/orange-report/Leaf_3.0.png",
          "sentiment-3.5":
            self.baseUrl + "/media/assets/orange-report/Leaf_3.5.png",
          "sentiment-4.0":
            self.baseUrl + "/media/assets/orange-report/Leaf_4.0.png",
          "sentiment-4.5":
            self.baseUrl + "/media/assets/orange-report/Leaf_4.5.png",
          "sentiment-5.0":
            self.baseUrl + "/media/assets/orange-report/Leaf_5.0.png"
        },
        pageSize: "LETTER",
        pageMargins: [20, 20, 20, 20],
        content: [
          {},
          {
            columns: [
              {
                width: "*",
                stack: [
                  {
                    columns: [
                      {
                        width: "auto",
                        stack: [
                          {
                            image: "ynLogo",
                            width: 100,
                            margin: [5, 0, 5, 0]
                          },
                          {
                            text: "Syncscape Performance Report",
                            alignment: "left",
                            margin: [10, 10, 0, 0],
                            fontSize: 10
                          },
                          {
                            text:
                              new Date().toLocaleDateString("en-us", {
                                year: "numeric",
                                month: "long",
                                day: "numeric"
                              }) + " (last 30 days)",
                            alignment: "left",
                            margin: [10, 10, 0, 0],
                            fontSize: 10
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                width: "auto",
                stack: [
                  {
                    text: "Syncscape",
                    alignment: "center",
                    margin: [5, 5, 5, 5]
                  },
                  {
                    text: "Top 20",
                    alignment: "center",
                    margin: [5, 5, 5, 5]
                  },
                  {
                    text: "Based On Recent Activity",
                    alignment: "center",
                    margin: [5, 5, 5, 5]
                  }
                ]
              }
            ]
          },
          "\n",
          {
            table: {
              widths: ["auto", "*", "*", "*", "*", "*", "*", "*"],
              headerRows: 2,

              body: reportBody
            }
          },
          "\n\n"
        ],
        defaultStyle: {
          fontSize: 8,
          bold: false
        },
        styles: {
          heading: {
            color: "#E06521",
            bold: true,
            margin: [0, 0, 0, 5]
          },
          icon: { font: "Fontello" },

          mt5: { margin: [0, 5, 0, 0] },
          mt10: { margin: [0, 10, 0, 0] },
          mb5: { margin: [0, 0, 0, 5] },
          mb10: { margin: [0, 0, 0, 10] },
          my5: { margin: [0, 5, 0, 5] },
          my10: { margin: [0, 10, 0, 10] }
        }
      };
      pdfMake.createPdf(docDefinition).open();
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();

            this.getOrangeReport();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
        });
    },

    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Orange Reports",
          route: "/customers/" + this.customerId + "/orange-reports"
        },
        { title: "View Orange Report" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Orange Reports",
          route: "/customers/" + this.customerId + "/orange-reports"
        },
        { title: "View Orange Report" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    irrigationSystemHealthInfo() {
      switch (this.orangeReport.irrigationSystemHealth) {
        case 0: //All Go With The Flow
          return {
            name: "All Go With The Flow",
            picture:
              "/media/assets/irrigation-system-health/icons-all-go-with-the-flow.png"
          };
        case 1: //Needs Attention
          return {
            name: "Needs Attention",
            picture:
              "/media/assets/irrigation-system-health/icons-needs-attention.png"
          };
        case 2: //Under Repair
          return {
            name: "Under Repair",
            picture:
              "/media/assets/irrigation-system-health/icons-under-repair.png"
          };

        default:
          return {
            name: "All Go With The Flow",
            picture:
              "/media/assets/irrigation-system-health/icons-all-go-with-the-flow.png"
          };
      }
    }
  }
};
</script>

<style scoped>
.canEnlargeImage img:after {
}
</style>
